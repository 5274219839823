/*
 * @Author: 陈传勋 593837024@qq.com
 * @Date: 2022-10-14 09:49:14
 * @LastEditors: 陈传勋 593837024@qq.com
 * @LastEditTime: 2022-10-14 10:37:34
 * @FilePath: /xlian-coe-doctor/src/api/index.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
let apis: any = {};
const files = import.meta.globEager('./*.ts');
Object.keys(files).forEach((key) => {
    apis = Object.assign(apis, files[key].default)
});

export default apis