import { createApp } from 'vue';
import { ConfigProvider } from 'vant';
import App from './App.vue';
import router from '@/router';
import store from '@/store';
import api from '@/api';
import 'amfe-flexible';

import { Toast } from 'vant';
import { Dialog } from 'vant';
import { ImagePreview } from 'vant';

import 'vant/es/toast/style/index';
import 'vant/es/dialog/style/index';

const app = createApp(App);

// 依赖注入api
app.provide('$api', api);
app.provide('$toast', Toast);
app.provide('$dialog', Dialog);

app.use(store);

app.use(router);

app.use(ConfigProvider);

app.mount('#app');
