/*
 * @Author: 陈传勋 593837024@qq.com
 * @Date: 2022-10-13 13:56:56
 * @LastEditors: 陈传勋 593837024@qq.com
 * @LastEditTime: 2023-02-28 11:30:48
 * @FilePath: /xlian-coe-doctor/src/store/moduleA.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import apis from '@/api';
import router from '@/router';
// import { ROUTER_BASE_URL } from '@/env';

interface StateObj {
  doctorInfo: { doctorCode: string };
  chinaCode: Array<object>;
  keepAlive: Array<string>;
  uploadOpenId: Object;
}

// state
const state = {
  doctorInfo: {
    doctorCode: '',
  },
  chinaCode: [], // 中国省市区编码集合,
  keepAlive: [],
  // 上报OpenId的缓冲
  uploadOpenId: null,
};

// getter
const getters = {
  doctorInfo: (state: StateObj) => state.doctorInfo,
  doctorCode: (state: StateObj) => state.doctorInfo.doctorCode,
};

// mutations
const mutations = {
  UPDATE_DOCTOR_INFO(state: StateObj, doctorInfo: { doctorCode: string }) {
    state.doctorInfo = doctorInfo;
  },
  SAVE_AREA_CODE: (state: StateObj, chinaCode: Array<object>) => {
    state.chinaCode = [...chinaCode];
  },
  UPDATE_CACHE: (state: StateObj, componentName: string) => {
    state.keepAlive.push(componentName);
  },
  CLEAN_CACHE: (state: StateObj) => {
    state.keepAlive = [];
  },
  ADD_WAIT_UPLOAD_OPENID(state: StateObj, fn: Function) {
    state.uploadOpenId = fn;
  },
  UPLOAD_OPENID(state: StateObj, loginType: string) {
    const fn = state.uploadOpenId;
    if (fn instanceof Function) {
      fn(loginType);
      state.uploadOpenId = null;
    }
  },
};

// action
const actions = {
  async getDoctorInfo({ commit }: { commit: any }) {
    const { doctorCode } = state.doctorInfo;
    const token = window.localStorage.getItem('TOKEN') || '';

    /** 缓存读取 */
    if (doctorCode && token) {
      return Promise.resolve(state.doctorInfo);
    }
    /** 后端数据读取 */
    const data = await apis.requestCoeDoctorInfo();

    commit('UPDATE_DOCTOR_INFO', data);

    /** webfunny 用户信息更新 */
    // updateWfIdInfo();

    // 协议同意
    window.localStorage.setItem('agree-cooperative', data.isSign ? '1' : '');

    return Promise.resolve(state.doctorInfo);
  },
  async getchinaCode({ commit, state }: { commit: any; state: StateObj }) {
    const chinaCode = state.chinaCode ?? [];
    if (chinaCode.length > 0) {
      return;
    }
    const data = await apis.getChinaCode(0);
    commit('SAVE_AREA_CODE', data);
  },
  updateCache({ commit }: { commit: any }, componentName: string) {
    commit('UPDATE_CACHE', componentName);
  },
  cleanCache({ commit }: { commit: any }) {
    commit('CLEAN_CACHE');
  },
  // 登陆公众号获取openId
  async loginOfficial({ commit }) {
    const { query, fullPath } = router.currentRoute.value;
    const origin = location.origin;
    if (query.code && query.appId) {
      window.history.pushState({ title: 'title', url: '#' }, 'title', '#');
      return Promise.resolve();
    } else {
      const redirectUrl = `${origin}/${fullPath}`;
      location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${
        query.appId
      }&redirect_uri=${encodeURIComponent(
        redirectUrl || '',
      )}&response_type=code&scope=snsapi_base#wechat_redirect`;
      return Promise.reject();
    }
  },
};

export default {
  namespaced: true,
  state: { ...state },
  getters: { ...getters },
  mutations: { ...mutations },
  actions: { ...actions },
};
