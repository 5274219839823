<script setup lang="ts">
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://vuejs.org/api/sfc-script-setup.html#script-setup
// import BasicConfigInfo from '@/utils/preRequest';
// import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
// import { onMounted, ref, reactive } from 'vue';
// import { loadSdk } from '@/utils/tools';

// let instance = BasicConfigInfo.getInstance();
// instance.getBasicConfig();

// const route = useRoute();
const store = useStore();
// onMounted(() => {
//   loadSdk()
// })
</script>

<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="store.state.user.keepAlive">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<style>
#app {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  padding: 0;
  font-size: 28px;
  color: var(--text-color);
  background-color: var(--bg-color);
  -webkit-overflow-scrolling: auto;
}
</style>
