/*
 * @Author: 陈传勋 593837024@qq.com
 * @Date: 2022-10-13 13:50:10
 * @LastEditors: 陈传勋 593837024@qq.com
 * @LastEditTime: 2022-10-13 15:56:32
 * @FilePath: /xlian-coe-doctor/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore } from 'vuex';
import getters from './getters';

const modules: any = {};
const modulesFiles = import.meta.globEager('./modules/*.ts');
Object.keys(modulesFiles).forEach((fileName) => {
    const name = fileName.replace(/(\.\/modules\/|\.ts)/g, '');
    modules[name] = modulesFiles[fileName].default;
});
const store = createStore({
    modules,
    getters: getters as any,
});

export default store;
