import {
  createRouter,
  createWebHistory,
  RouteRecordName,
  RouteRecordRaw,
} from 'vue-router';
import store from '@/store';
import api from '@/api';
import { CACHE_VERSION } from '@/constants';
import { ROUTER_BASE_URL } from '@/env';

const whiteRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '主页',
    },
    component: () => import('@/pages/home/index.vue'),
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '访问失败',
    },
    component: () => import('@/pages/home/404.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
];

const routes: Array<RouteRecordRaw> = [];

const genFullPath = (toPath: string, toQuery = {}) => {
  const businessCode = window.localStorage.getItem(
    `businessCode-${CACHE_VERSION}`,
  );
  const appId = window.localStorage.getItem(
    `appId-${ROUTER_BASE_URL}-${CACHE_VERSION}`,
  );

  const mergeQuery: any = {
    ...toQuery,
  };
  let fullPath = toPath;

  /** 全局混入businessCode */
  if (businessCode) {
    mergeQuery.businessCode = businessCode;
  }

  /** 全局混入appId */
  if (appId) {
    mergeQuery.appId = appId;
  }

  const queryStr = Object.keys(mergeQuery)
    .map((key) => `${key}=${mergeQuery[key]}`)
    .join('&');

  if (queryStr) {
    fullPath = `${toPath}?${queryStr}`;
  }

  return fullPath;
};

const mergeToQuery = (toQuery: object, mergeQuery: any): object => {
  const notNullMergeQuery = Object.keys(mergeQuery).reduce((acc: any, key) => {
    if (mergeQuery[key] !== undefined && mergeQuery[key] !== null) {
      acc[key] = mergeQuery[key];
    }

    return acc;
  }, {});

  const finalQuery = {
    ...toQuery,
    ...notNullMergeQuery,
  };

  return finalQuery;
};

const router = createRouter({
  history: createWebHistory(),
  routes: whiteRoutes,
});

router.beforeEach(async (to, from, next) => {
  const title = to.meta?.title || '杏联健康';
  const toPath = to?.path;
  // const toFullPath = to.fullPath;
  // const toQuery = to?.query ?? {};

  if (typeof title == 'string') {
    document.title = title;
  }
  // const hasToken = window.localStorage.getItem('TOKEN') || '';
  // const authPath: Array<RouteRecordName> = routes.map((item) => item.path);
  // const hasGetUserInfo = store.getters.doctorInfo?.doctorCode;
  // const businessCode =
  //   window.localStorage.getItem(`businessCode-${CACHE_VERSION}`) || undefined;
  // const appId =
  //   window.localStorage.getItem(`appId-${ROUTER_BASE_URL}-${CACHE_VERSION}`) ||
  //   undefined;

  // const isInWhiteList = whiteRoutes.map((e) => e.path).includes(toPath);

  // to.fullPath = genFullPath(toPath, toQuery);

  // to.query = mergeToQuery(toQuery, { businessCode, appId }) as any;

  // if (isInWhiteList) {
  //   next();

  //   return;
  // }

  // if (!hasToken) {
  //   const fullPath = to?.fullPath;

  //   if (to.path !== '/login') {
  //     next({
  //       path: '/login',
  //       query: {
  //         businessCode,
  //         appId,
  //         redirectUrl:
  //           fullPath !== '/' ? encodeURIComponent(fullPath) : undefined,
  //       },
  //     });
  //   }

  //   next();

  //   return;
  // }

  // if (!hasGetUserInfo) {
  //   try {
  //     await store.dispatch('user/getDoctorInfo');
  //   } catch (e) {
  //     const fullPath = to?.fullPath;

  //     if (to.path !== '/login') {
  //       next({
  //         path: '/login',
  //         query: {
  //           businessCode,
  //           appId,
  //           redirectUrl:
  //             fullPath !== '/' ? encodeURIComponent(fullPath) : undefined,
  //         },
  //       });

  //       return;
  //     }
  //   }

  //   next();

  //   return;
  // }

  next();
});

// router.afterEach((to, from) => {
//   const { code, appId } = to.query;
//   const sessionOpenId = window.sessionStorage.getItem(
//     `openId-${ROUTER_BASE_URL}-${CACHE_VERSION}`,
//   );
//   if (sessionOpenId) return;
//   if (code && appId) {
//     const getOpenId = async (loginType: string) => {
//       const data = await api.requestOpenId({
//         code,
//         appId,
//       });
//       const openId = data.openId || (sessionOpenId as string);
//       await api.upOfficeOpenId({
//         officeOpenID: openId,
//         upType: loginType,
//         appId,
//       });
//       if (openId) {
//         window.sessionStorage.setItem(
//           `openId-${ROUTER_BASE_URL}-${CACHE_VERSION}`,
//           openId as string,
//         );
//       }
//       if (appId) {
//         window.localStorage.setItem(
//           `appId-${ROUTER_BASE_URL}-${CACHE_VERSION}`,
//           appId as string,
//         );
//       }
//     };
//     const hasToken = window.localStorage.getItem('TOKEN') || '';
//     if (hasToken) {
//       getOpenId('2');
//     } else {
//       store.commit('ADD_WAIT_UPLOAD_OPENID', getOpenId);
//     }
//   }
// });

export default router;
