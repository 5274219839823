import axios, {
  AxiosResponse,
  AxiosRequestConfig,
  HeadersDefaults,
} from 'axios';
import { CACHE_VERSION } from '@/constants';
import { BASE_URL, PLATFORM_TYPE, BIZ_TYPE, VERSION } from '@/env';
import { Md5 } from 'ts-md5';
import { Toast, Dialog } from 'vant';
import BasicConfigInfo from '@/utils/preRequest';

import { useRouter } from 'vue-router';

const router = useRouter();

interface CustomHeader {
  platformType: string;
  biztype: string;
  version: string;
}

let loadingQueue: Array<string> = [];

class ResponseDataImpl implements ResponseData {
  constructor() {
    this.code = -1;
    (this.data = {}), (this.msg = 'no response data'), (this.success = false);
  }
  code: number;
  data: object;
  msg: string;
  success?: boolean | undefined;
  tid?: string | undefined;
}

// 配置默认接口地址
axios.defaults.baseURL = BASE_URL;
// 配置自定义请求头请求头
const header: CustomHeader = {
  platformType: PLATFORM_TYPE.H5,
  biztype: BIZ_TYPE.COE_DOCTOR,
  version: VERSION,
};

axios.defaults.headers = {
  ...axios.defaults.headers,
  ...header,
};

const service = axios.create(); // Request interceptors

service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    // 设置请求头Authorization 存放token
    let TOKEN: string = window.localStorage.getItem('TOKEN') || '';
    const hideLoading = config?.hideLoading ?? false;
    const url = config?.url as string;

    loadingQueue.push(url);

    if (url.indexOf('/file/') > -1) {
      config.baseURL = import.meta.env.VITE_FILE_BASE_URL;
    }

    if (config?.headers?.token) {
      TOKEN = config.headers.token || '';
      delete config.headers.token;
    }
    if (config?.headers) {
      // 添加token
      config.headers.Authorization = TOKEN;
      // md5加数字签名
      const timestamp = new Date().getTime();
      config.headers.timestamp = timestamp;

      config.headers.sign = Md5.hashStr(
        JSON.stringify(config.data) + timestamp + TOKEN,
      );
      config.timeout = 40 * 1000;
    }

    if (!hideLoading) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
        loadingType: 'spinner',
      });
    }

    return config;

    // 请求基本环节配置
    // return new Promise((resolve, reject) => {
    // const instance = BasicConfigInfo.getInstance();
    // instance
    //   .getBasicConfig()
    //   .then((res: any) => {
    //     if (config?.headers) {
    //       config.headers.versionDay = res.versionDay;
    //     }
    //     if (config.url?.indexOf('/file/') > -1) {
    //       config.baseURL = import.meta.env.VITE_FILE_BASE_URL;
    //     }
    //     resolve(config);
    //   })
    //   .catch(() => {
    //     loadingQueue = [];
    //     Toast.clear();

    //     Dialog.alert({
    //       title: '版本更新',
    //       message: '检测到新版本，是否更新？',
    //     }).then(() => {
    //       location.reload();
    //     });
    //   });
    // });
  },
  (error: any) => {
    Promise.reject(error);
  },
);

service.interceptors.response.use(
  async (res: AxiosResponse) => {
    const hideErrToast = res.config?.hideErrToast ?? false;
    const url = res?.config?.url as string;

    const idx =
      loadingQueue.indexOf(url) > -1 ? loadingQueue.indexOf(url) : 100;

    loadingQueue.splice(idx, 1);

    if (loadingQueue?.length < 1) {
      Toast.clear();
    }

    const resData = (res?.data as ResponseData) ?? new ResponseDataImpl();
    if (resData.code === 200) {
      return Promise.resolve(resData.data);
    } else if (resData.code === 401) {
      window.localStorage.removeItem('TOKEN');
    } else {
      if (!hideErrToast) {
        Toast(resData.msg || '网络繁忙');
      }
    }
    return Promise.reject(resData);
  },
  (error: any) => {
    loadingQueue = [];

    Toast.clear();
    // do something
    Toast('网络繁忙');
    return Promise.reject(error);
  },
);

export default service;
