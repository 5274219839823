import request from '@/utils/axios';

export default {
  //  获取微信鉴权
  getWxSignature(data = {}) {
    return request.post(`/open/openTool/wxSignature`, data, {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        platformtype: 'web',
        appId: 'haili001',
        Authorization: '',
      },
    });
  },
  // 获取小程序链接
  getWxUrl({ shortLink = '', params = [], index }) {
    return request.post(`/admin/commonLink/getWxMinAppUrlLink`, {
      shortLink,
      params,
      ...(index && { index }),
    });
  },
  // 获取询山h5链接
  getShortLink({ shotLinkCode }: { shotLinkCode: string }) {
    return request.get(`/admin/weChat/getShotLink/${shotLinkCode}`);
  },
};
